import {
  AfterViewInit,
  Component,
  effect,
  EventEmitter,
  inject,
  input,
  Output,
  signal,
  untracked,
} from "@angular/core";
import { FullCalendarModule } from "@fullcalendar/angular";
import { CalendarOptions, EventInput } from "@fullcalendar/core";
import { TranslateService } from "@ngx-translate/core";
import { createState } from "../../../../../harmony/angular";
import { BrandPublicationCalendarEventComponent } from "../../../../brand/brand-campaign/content/pages/brand-publication-calendar/calendar-event-publication-with-summary/brand-publication-calendar-event.component";
import { CalendarEventPublicationComponent } from "../../../../brand/brand-campaign/publication-template/pages/publication-template-schedule/calendar-event-publication/calendar-event-publication.component";
import { CalendarDayCellComponent } from "../../../../shared/content/components/calendar-day-cell/calendar-day-cell.component";
import { FilterOutput } from "../../../../shared/components/filtering/filter.interfaces";
import { SortingOption } from "../../../../shared/components/filtering/sorting-selector/sorting-selector.component";
import { ReloadComponent } from "../../../../shared/components/reload/reload.component";
import { SharedComponentsModule } from "../../../../shared/components/shared-components.module";
import { PublicationWithSummaryCalendarEventFactory } from "../../../../shared/content/components/publication-calendar/calendar-event-mappers/publication-calendar-event.mappers";
import { PublicationCalendarConfig } from "../../../../shared/content/components/publication-calendar/publication-calendar.config";
import { PublicationCalendarState } from "../../../../shared/content/components/publication-calendar/publication-calendar.state";
import { PublicationTemplateAggregatedMetricsComponent } from "../../../../shared/content/components/publication-template-aggregated-metrics/publication-template-aggregated-metrics.component";
import { GetPublicationAggregatedCalendarWithSummaryInteractor } from "../../../../shared/content/domain/interactors/get-publication-aggregated-calendar-with-summary.interactor";
import { GetPublicationAggregatedMetricsInteractor } from "../../../../shared/content/domain/interactors/get-publication-aggregated-metrics.interactor";
import { PartnerCampaignService } from "../../../../shared/services/api/partner-campaign.service";
import { assert } from "../../../../shared/utils/assert";
import { PartnerPublicationCalendarEventComponent } from "./calendar-event-publication-with-summary/partner-publication-calendar-event.component";

@Component({
  standalone: true,
  selector: "app-partner-publication-calendar",
  templateUrl: "./partner-publication-calendar.component.html",
  styleUrls: ["./partner-publication-calendar.component.scss"],
  imports: [
    SharedComponentsModule,
    ReloadComponent,
    CalendarDayCellComponent,
    CalendarEventPublicationComponent,
    FullCalendarModule,
    PartnerPublicationCalendarEventComponent,
    PublicationTemplateAggregatedMetricsComponent,
    BrandPublicationCalendarEventComponent,
  ],
  providers: [PublicationWithSummaryCalendarEventFactory],
})
export class PartnerPublicationCalendarComponent implements AfterViewInit {
  protected readonly state = createState<PublicationCalendarState>({
    $type: "list",
    metrics: undefined,
    publications: undefined,
  });
  private readonly translateService = inject(TranslateService);
  protected readonly campaign = inject(PartnerCampaignService)
    .currentPartnerCampaign;
  private readonly publicationEventFactory = inject(
    PublicationWithSummaryCalendarEventFactory,
  );
  private readonly getPublicationsWithSummary = inject(
    GetPublicationAggregatedCalendarWithSummaryInteractor,
  );
  private readonly getAggregatedMetrics = inject(
    GetPublicationAggregatedMetricsInteractor,
  );
  private readonly pageSize = 10;
  protected readonly EmptyArray = Array.from({ length: this.pageSize });
  protected readonly calendarConfig?: CalendarOptions;
  protected calendarEvents: EventInput[] = [];
  public readonly filters = input.required<FilterOutput[]>();
  public readonly sorting = input.required<SortingOption>();
  protected readonly currentPage = signal(1);
  protected readonly totalPages = signal(0);

  @Output() public readonly resetFilters = new EventEmitter<void>();
  @Output() public readonly noContent = new EventEmitter<void>();

  constructor() {
    this.calendarConfig = PublicationCalendarConfig(
      this.campaign,
      this.translateService,
    );

    effect(async () => {
      this.updatePublications();
    });
  }

  public ngAfterViewInit(): void {
    this.updatePublications();
  }

  public updatePublications(): void {
    const filters = this.filters();
    const sorting = this.sorting();
    const page = this.currentPage();
    untracked(() => {
      void this.getPublications(filters, sorting, page);
    });
  }

  protected async getPublications(
    filters: FilterOutput[],
    sorting?: SortingOption,
    pageIndex?: number,
  ): Promise<void> {
    this.state.set({
      $type: "list",
      metrics: undefined,
      publications: undefined,
    });

    try {
      this.getAggregatedMetrics
        .execute(this.pageSize, pageIndex, filters, sorting)
        .then((metrics) => {
          assert(this.state.$type === "list");
          this.state.set({
            $type: this.state.$type,
            metrics: metrics,
            publications: this.state.publications,
          });
        });

      this.getPublicationsWithSummary
        .execute(this.pageSize, pageIndex, filters, sorting)
        .then((list) => {
          if (list.totalSize === 0) {
            this.state.set({ $type: "no-results" });
            this.noContent.emit();
            return;
          }

          this.calendarEvents = list.entries.flatMap(
            (publication) =>
              this.publicationEventFactory.create(publication) as EventInput,
          );

          assert(this.state.$type === "list");
          this.state.set({
            $type: "list",
            metrics: this.state.metrics,
            publications: list.entries,
          });
          this.totalPages.set(Math.ceil(list.totalSize / this.pageSize));
        });
    } catch {
      this.state.set({ $type: "error" });
    }
  }
}
